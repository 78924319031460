import {Box, Stack} from '@mui/material'
import React from 'react'

import {MonthsFilterOptions} from '../../enums'
import {Group} from '../../types'

import {CategoriesTree} from './CategoriesTree'
import {ResourceCard} from './ResourceCard'
import {ResourceCardMobileView} from './ResourceCardMobileView'

export const MonthlyEntryView = ({
  showTreeContainer,
  selectedDate,
  setSelectedResource,
  selectedResource,
  isMobile,
  periodType,
  showDetails
}: {
  showTreeContainer: boolean
  selectedDate: string
  setSelectedResource: (group: Group) => void
  selectedResource?: Group
  isMobile?: boolean
  periodType: MonthsFilterOptions
  showDetails?: boolean
}) => {
  return (
    <Box display="flex" flexDirection="row" gap={2} justifyItems="space-between" width="100%">
      {showTreeContainer && (
        <CategoriesTree
          selectedResource={selectedResource}
          setSelectedResource={setSelectedResource}
          selectedDate={selectedDate}
          width={isMobile ? '100%' : '25%'}
        />
      )}
      {showDetails && selectedResource && (
        <Stack width={isMobile ? '100%' : '75%'} gap={2}>
          {selectedResource.groups ? (
            selectedResource.groups?.map((group) =>
              !isMobile ? (
                <ResourceCard
                  key={group.id}
                  selectedResource={group}
                  selectedDate={selectedDate}
                  periodType={periodType}
                />
              ) : (
                <ResourceCardMobileView
                  key={group.id}
                  selectedResource={group}
                  selectedDate={selectedDate}
                />
              )
            )
          ) : !isMobile ? (
            <ResourceCard
              key={selectedResource.id}
              selectedResource={selectedResource}
              selectedDate={selectedDate}
              periodType={periodType}
            />
          ) : (
            <ResourceCardMobileView
              key={selectedResource.id}
              selectedResource={selectedResource}
              selectedDate={selectedDate}
            />
          )}
        </Stack>
      )}
    </Box>
  )
}
