import {Material, MaterialSource} from '@hconnect/common/types'
import {dataTestId} from '@hconnect/uikit'
import {Card, CardTitle} from '@hconnect/uikit/src/lib2'
import {Add, InfoOutlined} from '@mui/icons-material'
import {Box, Button, Stack, Typography} from '@mui/material'
import React, {useState} from 'react'
import {useTranslation} from 'react-i18next'

import {usePermission} from '../../../permissions'
import {useMaterialWithRecipesQuery, useMaterialsProductsQuery} from '../../hooks'
import type {Product} from '../../types'

import {getAvailableProducts} from './helpers'
import {ProductForm} from './ProductForm'
import {SalesForecastInfo} from './salesForecast'

interface ProductsCardProps {
  material: Material
}

export const ProductsCard: React.FC<ProductsCardProps> = ({material}) => {
  const {t} = useTranslation()
  const {data: materialWithRecipes} = useMaterialWithRecipesQuery(material.id)
  const {data: materialsProducts} = useMaterialsProductsQuery(materialWithRecipes?.source)
  const [newProduct, setNewProduct] = useState<Omit<Product, 'id'> & {id?: number}>()

  const canChangeMaterials = usePermission('CHANGE_MATERIALS')

  if (!materialWithRecipes || !materialsProducts) return null

  const attachedProducts = materialWithRecipes.products
  const allProducts = Object.values(materialsProducts).flat()
  // it shouldn't be possible to attach same product twice
  const allAvailableProducts = getAvailableProducts(allProducts, materialWithRecipes)

  const isMaterialProducedInPlant = material.source === MaterialSource.ProducedInPlant

  const isAddNewProductDisabled =
    Boolean(newProduct) || allAvailableProducts.length === 0 || !canChangeMaterials

  const titleText = isMaterialProducedInPlant
    ? t('materialsSettings.sapOrErpCodes', {amount: attachedProducts.length ?? 0})
    : t('materialsSettings.sapOrErpCode')

  const products: (Pick<Product, 'code' | 'type'> & {id?: number})[] = [
    ...attachedProducts,
    ...(newProduct ? [newProduct] : [])
  ]

  return (
    <Card
      {...dataTestId('attached_products')}
      headerContent={
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
          <Box>
            <CardTitle sx={{mb: 0}} {...dataTestId('attached_products_title')}>
              {titleText}
            </CardTitle>
            {isMaterialProducedInPlant && (
              <Typography variant="caption">
                <InfoOutlined
                  sx={{
                    mr: 0.5,
                    verticalAlign: 'middle',
                    fontSize: 'inherit'
                  }}
                />
                {t('materialsSettings.sapOrErpCodesInfo')}
              </Typography>
            )}
          </Box>
          <Button
            variant="text"
            startIcon={<Add />}
            disabled={isAddNewProductDisabled}
            onClick={() => {
              const [firstProduct] = allAvailableProducts
              if (firstProduct) {
                setNewProduct({
                  type: firstProduct.type,
                  code: '',
                  name: ''
                })
              }
            }}
            {...dataTestId('add_material_code')}
          >
            {t('materialsSettings.addCode')}
          </Button>
        </Box>
      }
    >
      <Stack spacing={3}>
        {products.map((product, index) => (
          <Stack key={product.id ?? 'new'} {...dataTestId('material_product_row')}>
            <ProductForm
              index={index}
              product={product}
              productsByType={materialsProducts}
              material={materialWithRecipes}
              isReadOnly={!canChangeMaterials}
              setNewProduct={setNewProduct}
            />
            {product.id && isMaterialProducedInPlant && (
              <SalesForecastInfo productId={product.id} materialId={materialWithRecipes.id} />
            )}
          </Stack>
        ))}
      </Stack>
    </Card>
  )
}
